import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, } from "react-bootstrap";

const NavbarNav = () => {
    const [changeColor, setChangeColor] = useState(false);
    const changeBackgroundColor = () => {
        if (window.scrollY > 750) {
            setChangeColor(true);
        } else {
            setChangeColor(false);
        }
    };
    useEffect(() => {
        changeBackgroundColor();
        window.addEventListener('scroll', changeBackgroundColor)
    })
    return (
        <div className="sticky-top">
            <Navbar variant="dark" expand="lg" className={changeColor ? "color-active" : ""}>
                <Container>
                    <Navbar.Brand href="#home" className="fw-bold">Pojok Media</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#home" className="mx-2">Home</Nav.Link>
                            <Nav.Link href="#gallery" className="mx-2">Gallery</Nav.Link>
                            <Nav.Link href="#team" className="mx-2">Our Team</Nav.Link>
                            <Nav.Link href="#produksi" className="mx-2">Produksi</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
export default NavbarNav;