import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Cofee1 from "../assets/images/cofee01.jpg";
import Cofee2 from "../assets/images/cofee02.jpg";
import Cofee3 from "../assets/images/cofee03.jpg";
import Cofee4 from "../assets/images/cofee04.jpg";
import Cofee5 from "../assets/images/cofee05.jpg";
import Cofee6 from "../assets/images/cofee06.jpg";
import Cofee7 from "../assets/images/cofee07.jpg";
import Cofee8 from "../assets/images/cofee08.jpg";


const Gallery = () => {
    return (
        <div className='gallery min-vh-100 mt-5'>
            <h1 className='text-center mt-5 fw-bold animate__animated animate__zoomIn' id='gallery'>Gallery</h1>
            <Container>
                <Row className='row-cols-lg-4 row-cols-md-2 row-cols-sm-1 mt-5'>
                    <Col><img src={Cofee1} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="50" /></Col>
                    <Col><img src={Cofee2} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="200" /></Col>
                    <Col><img src={Cofee3} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="300" /></Col>
                    <Col><img src={Cofee4} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="350" /></Col>
                {/* </Row>
                <Row className='row-cols-lg-4 row-cols-md-3 row-cols-sm-1'> */}
                    <Col><img src={Cofee5} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="450" /></Col>
                    <Col><img src={Cofee6} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="550" /></Col>
                    <Col><img src={Cofee7} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="650" /></Col>
                    <Col><img src={Cofee8} alt='Cofee' className='w-100 mb-4' data-aos="fade-up" data-aos-delay="750" /></Col>
                </Row>
            </Container>
        </div>
    )
}
export default Gallery;

