import React,{useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Container, Card, Button, CardHeader }from 'react-bootstrap';

const AddUser = () => {
    const [nama,setNama] = useState("");
    const [alamat,setAlamat] = useState("");
    const [email,setEmail] = useState("");
    const navigate = useNavigate();

    const saveUser = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:5000/users', {
                nama,
                alamat,
                email
            });
            navigate("/")
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Container >
      <Row className="justify-content-center">
        <Col lg={6} md={8} sm={10}>
        <Card>
                <CardHeader className='text-center'>Add | Update data</CardHeader>
                <Card.Body>
                <Form onSubmit={saveUser}>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="4">
                        Nama
                        </Form.Label>
                        <Col sm="8">
                        <Form.Control type="text" placeholder='Nama Lengkap' 
                        value={nama} onChange={(e)=> setNama(e.target.value)}
                        />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="4">
                        Alamat
                        </Form.Label>
                        <Col sm="8">                        
                        <Form.Control type="text" placeholder="Alamat.."
                        value={alamat} onChange={(e)=> setAlamat(e.target.value)}
                        />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="4">
                        Email
                        </Form.Label>
                        <Col sm="8">
                        <Form.Control type="email" placeholder="email" value={email} onChange={(e)=> setEmail(e.target.value)}
                        />
                        </Col>
                    </Form.Group>
                    <div>
                    <Button variant="outline-primary">Save</Button>
                    </div>
                </Form>
                </Card.Body>
            </Card>
        </Col>
      </Row>
    </Container>

    )
}
export default AddUser

