import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

export const Hero = () => {
    return (
        <div className='hero min-vh-100 w-100'>
            <Container>
                <Row>
                    <Col >
                        <h1 className='text-center animate__animated animate__fadeInUp'>Marilah kita Happy Sejenak</h1>
                        <p className="text-center text-white-50 animate__animated animate__fadeInUp animate__delay-2">Nikmati Kopi Selagi masih panas!!</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Hero;