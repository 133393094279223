import {BrowserRouter, Routes, Route} from "react-router-dom";
import Hero from "./components/Hero.js";
import NavbarNav from "./components/NavbarNav.js";
// import UserList from "./components/UserList.js";
import Gallery from "./components/Gallery.js";
import AddUser from "./components/AddUser.js";

function App() {
  return (
    <div>
      <Hero />
      <NavbarNav />
      {/* Content */}

      <Gallery />
      {/* <UserList /> */}
      {/* Content */}
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<UserList/>} /> */}
          <Route path="add" element={<AddUser/>} />
        </Routes>
      </BrowserRouter>
      {/* <AddUser /> */}
    </div>
  );
}

export default App;
